import React, { Fragment, useState } from 'react';
import gem from '../../../Dashboard/images/gem.svg';
import { Button, Modal } from '@axeedge/go-pupil-components';
import BuyCard from '../BuyCard';
import ImageFadeIn from "react-image-fade-in";

import styles from './CardLocked.module.scss';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

const CardLocked = ({ selectedPack, card, setFlipAnimation, canBuyWinner }) => {

    const { t } = useTranslation(['rewardcards', 'translation']);
    const [buyCard, setBuyCard] = useState();
    
    return(
        <Fragment>
            <div onClick={() => setBuyCard(card)}  className={styles.cardImg}>
                <ImageFadeIn src={`../../images/cards/cover_${selectedPack.key}.svg`} alt='' />
                { selectedPack.key !== 'vegetable_friends' && <span className={styles.cardName}>{t(selectedPack.key)}</span>}  
            </div>  
             <Button outline onClick={() => setBuyCard(card)} className={cx(styles.cardPrice, {[styles.cardPriceLocked]: card.chapterWinnerOnly})}>
                <Fragment>
                    <img src={gem} alt=''/> {card.price === 1  ? `${card.price} ${t('translation:gem')}` : `${card.price} ${t('translation:gems')}`}
                </Fragment>
            </Button>  
            {
                buyCard && (
                    <Modal closeModal={() => setBuyCard(null)}>
                        <BuyCard canBuyWinner={canBuyWinner} setFlipAnimation={setFlipAnimation} selectedPack={selectedPack} card={buyCard} closeModal={() => setBuyCard(null)} />
                    </Modal>
                )
            } 
        </Fragment>
    )
}

export default CardLocked;