import React, { useState, useContext } from 'react';
import { Button, FormErrors } from '@axeedge/go-pupil-components';
import ImageFadeIn from 'react-image-fade-in';

import { useMutation } from '@apollo/react-hooks';
import { AuthContext } from '../../../../services/auth/AuthProvider';
import { BUY_REWARD_CARD } from '../../services/graphql';
import { useTranslation } from 'react-i18next';

import styles from '../../Rewards.module.scss';

const BuyCard = ({ card, selectedPack, closeModal, setFlipAnimation, canBuyWinner }) => {

    const { t } = useTranslation(['rewardcards', 'translation']);
    const { currentUser, setCurrentUser } = useContext(AuthContext);

    const [formErrors, setFormErrors] = useState([]);

    const [buyRewardCard, { loading }] = useMutation(BUY_REWARD_CARD, {
        update: (_, { data }) => {
            if (data.buyRewardCard.errors && data.buyRewardCard.errors.length !== 0) {
                setFormErrors(data.buyRewardCard.errors);
                return;
            }
            if (data.buyRewardCard.student.id) {
                closeModal();
                setFlipAnimation(true);

                setCurrentUser({
                    ...currentUser,
                    availableGems: data.buyRewardCard.student.availableGems,
                    studentRewardCards: data.buyRewardCard.student.studentRewardCards
                });
            }
        },
    });


    return (
        <div className={styles.buyCardModal}>
            <div className={styles.buyCardModalImgHolder}>
                <ImageFadeIn src={`../../images/cards/cover_${selectedPack.key}.svg`} alt='' />
                {selectedPack.key !== 'vegetable_friends' && <span className={styles.buyCardModalImgName}>{t(selectedPack.key)}</span>} 
            </div>

            {
                card.chapterWinnerOnly && !canBuyWinner ? <p>{t('need_to_buy_other_cards')}</p>
                    : (
                        <>
                            <p className={styles.buyCardModalText}>{t('this_card_costs')} {card.price} {card.price === 1 ? t('translation:gem').toLowerCase() : t('translation:gems').toLowerCase()}.</p>
                            <p className={styles.buyCardModalText}>{t('do_you_want_to_buy_it')}</p>
                            <div className={styles.buyCardModalButtons}>
                                <Button
                                    disabled={loading}
                                    onClick={() => buyRewardCard({
                                        variables: {
                                            studentId: currentUser.id,
                                            rewardCardId: card.id
                                        }
                                    })}
                                    className='u-m-right-1'>{loading ? t('translation:saving') : t('translation:button.yes')}</Button>
                                <Button outline type='button' onClick={() => closeModal()}>{t('translation:button.no')}</Button>
                            </div>
                        </>
                    )
            }

            {formErrors.length !== 0 && <FormErrors errors={formErrors.map(error => t(error))} />}
        </div>
    )
}

export default BuyCard;